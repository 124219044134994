import React, { useState,useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from '../../API/axios';
import { AppTitle } from "../../common/Helmate/Helmate";

export default function Profile() {

  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  AppTitle("TLD Threat Pulse | Profile")


  useEffect(() => {
    // Function to fetch user details
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get('/api/v1/user', {
          withCredentials: true, // Include cookies with the request
        });
        setEmail(response.data.email);
      } catch (err) {
        // Handle error
        if (err.response && err.response.status === 401) {
          navigate('/'); // Redirect to login if not authenticated
        }
      }
    };

    fetchUserDetails();
  }, [navigate]);


  return (
    <div className=' px-4'>
              <p className='text-xl font-semibold text-gray-600 mt-2 pb-2'>Profile Information</p>
              <hr className='text-gray-700'/>
              <div className='flex flex-col-2 gap-6 pt-8'>
              <div class="border border-gray-200">
              <dl> 
              <div class="bg-white px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-md font-medium text-gray-500">Email</dt>
                <dd class="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">{email}</dd>
                </div>
              </dl>
              </div>
              </div>
              </div>
  )
}
