import { useEffect, useState } from "react";
import axios from "axios";

const Changelog = () => {
  const [changelog, setChangelog] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get("/api/v1/changelog")
      .then((response) => {
        setChangelog(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching changelog:", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="p-6">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {changelog.map((entry) => (
            <div key={entry.id} className="bg-white shadow-md rounded-lg p-4 border">
              <h3 className="text-lg font-semibold">{entry.version}</h3>
              <p className="text-sm text-gray-500">{new Date(entry.date).toLocaleDateString()}</p>
              <p className="mt-2">{entry.description}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Changelog;
